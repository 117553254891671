import { Button } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from "react";
import { ClientPrincipal } from "../models/ClientPrincipal";
import { getUserInfo, isUserLoggedIn } from "../services/UserService";

const useStyles = makeStyles((theme) =>
  createStyles({
    loginButton: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  })
);

const SignInSignOutButton = () => {
  const [user, setUser] = useState<ClientPrincipal | null>(null);
  useEffect(() => {
    getUserInfo().then((user) => setUser(user));
  }, []);
  let signedIn = isUserLoggedIn(user);
  const classes = useStyles();
  return (
    <>
      {signedIn ? (
        <Button
          variant="outlined"
          href="/logout"
          className={classes.loginButton}
          fullWidth
        >
          Sign Out
        </Button>
      ) : (
        <Button
          variant="outlined"
          href="/login"
          className={classes.loginButton}
          fullWidth
        >
          Sign In / Up
        </Button>
      )}
    </>
  );
};

export { SignInSignOutButton };