import { Asset } from "../models/Asset"
import { RoadVehicle } from "../models/RoadVehicles"

const getAssets = async (): Promise<Asset[]> => {
    const path = `/api/dealershipgroups/*/assets`
    const options = {}
    const response = await fetch(path, options)
    const responseObject = response.json()
    return responseObject
}

const getTelematicsData = async (): Promise<RoadVehicle[]> => {
    const path = `/api/dealershipgroups/*/assets/*/telematics`
    const options = {}
    const response = await fetch(path, options)
    const responseObject = response.json()
    return responseObject
}

const updateAssets = async (assets: Asset[]): Promise<Asset[]> => {
    const path = `/api/dealershipgroups/*/assets`
    const options = {
        method: 'PUT',
        body: JSON.stringify(assets)
    }
    const response = await fetch(path, options)
    const responseObject = response.json()
    return responseObject
}

const bulkUploadAssetsWithFile = async (dealershipGroupId: string, subscriptionId: string, files: File[]): Promise<Response> => {
    const formData = new FormData();
    for (let file of files) {
        formData.append('assetFiles', file);
    }
    const response = await fetch(`api/dealershipgroups/${dealershipGroupId}/subscriptions/${subscriptionId}/upload`, {
        method: 'POST',
        body: formData
    })
    return response
}

export { getAssets, getTelematicsData, updateAssets, bulkUploadAssetsWithFile }