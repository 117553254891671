import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Grid,
  IconButton,
  Theme,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
  SelectChangeEvent,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { Asset } from '../models/Asset';
import { bulkUploadAssetsWithFile, getAssets, getTelematicsData, updateAssets } from '../services/AssetService';
import { RoadVehicle } from '../models/RoadVehicles';
import { format, parseISO } from 'date-fns';
import LoopIcon from '@mui/icons-material/Loop';
import InfoIcon from '@mui/icons-material/Info';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const useStyles = makeStyles((theme: Theme) => createStyles({
  table: {
    minWidth: 650,
  },
  success: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: "rgba(0, 255, 0, 0.04)",
      borderColor: theme.palette.success.main
    }
  },
  uploadButton: {
    marginTop: '10px'
  },
  inputLabel: {
    color: theme.palette.text.primary
  }
}));

enum ModificationState {
  Unmodified, ModifiedButNotSaved, SuccessfullyModifiedAndSaved
}

enum FileUploadState {
  NotUploaded, UploadFailed, UploadSucceeded
}

const Vehicles = () => {
  const classes = useStyles();
  const [assets, setAssets] = React.useState<Asset[] | undefined>(undefined)
  const [roadVehicles, setRoadVehicles] = React.useState<RoadVehicle[] | undefined>(undefined)
  const [modificationState, setModificationState] = React.useState<ModificationState>(ModificationState.Unmodified)
  const [fileUploadState, setFileUploadState] = React.useState<FileUploadState>(FileUploadState.NotUploaded);
  const [subscriptionIdForUpload, setSubscriptionIdForUpload] = React.useState<string>("")
  const [dealershipIdForUpload, setDealershipIdForUpload] = React.useState<string | undefined>(undefined)
  const [files, setFiles] = React.useState<File[]>([])
  const [message, setMessage] = React.useState<string>("")
  const refreshTelematics = () => getTelematicsData().then(results => setRoadVehicles(results));
  const refresh = () => {
    getAssets().then((obj) => {
      setAssets(obj);
      if (obj.length > 0) {
        setDealershipIdForUpload(obj[0]?.dealershipGroupId ?? undefined)
        setSubscriptionIdForUpload(obj[0]?.subscriptionId ?? "")
      }
      refreshTelematics();
      setModificationState(ModificationState.Unmodified)
    })
  }

  React.useEffect(() => {
    refresh()
    // eslint-disable-next-line
  }, [])
  const onPropertyChange = (index: number, event: any, setProperty: (currentAssets: Asset[], index: number, value: any) => Asset[]) => {
    let currentAssets = assets ?? [];
    setProperty(currentAssets, index, event)
    setAssets([...currentAssets])
    setModificationState(ModificationState.ModifiedButNotSaved)
  }
  const handleSubscriptionIdChange = (event: SelectChangeEvent) => {
    let subId = event.target.value;
    setSubscriptionIdForUpload(subId as string);
  };


  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files = Array.from(e.target.files ?? [])
    setFiles([...files])
  }

  const handleClick = async () => {
    try {
      const response = await bulkUploadAssetsWithFile(dealershipIdForUpload ?? "", subscriptionIdForUpload ?? "", files)
      const responseBodyText = await response.text()
      if (response.ok && !responseBodyText) {
        setMessage("")
        setFileUploadState(FileUploadState.UploadSucceeded)

        refresh()
      } else {
        const responseBody = JSON.parse(responseBodyText)
        setFileUploadState(responseBody && responseBody.statusCode && responseBody.statusCode >= 400 ? FileUploadState.UploadFailed : FileUploadState.UploadSucceeded);
        setMessage(responseBody && responseBody.value ? responseBody.value : "Sorry, that didn't work. Please try again later or contact our team.")
      }
    } catch (e: any) {
      setFileUploadState(FileUploadState.UploadFailed);
    }
  }
  const uploadFileButton = () => {
    switch (fileUploadState) {
      case FileUploadState.NotUploaded: {
        return (
          <Button
            variant="outlined"
            component="label"
            color="primary"
            onClick={handleClick}
            className={classes.uploadButton}
          >
            Submit Files
          </Button>
        )
      }
      case FileUploadState.UploadFailed: {
        return (
          <>
            <Button
              variant="outlined"
              component="label"
              color="secondary"
              onClick={handleClick}
              className={classes.uploadButton}
            >
              Submit Files
            </Button><Typography>{message.split("\n").map(chunk => (<span>{chunk}<br /></span>))}</Typography>
          </>
        )
      }
      case FileUploadState.UploadSucceeded: {
        return (
          <Button
            variant="outlined"
            component="label"
            className={classes.success + " " + classes.uploadButton}
            onClick={handleClick}
          >
            Success!
          </Button>
        )
      }
    }
  }


  const theme = useTheme();
  const biggerThanPhone = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <TableContainer component={Paper}>
        <Typography variant="subtitle1">Manage the vehicles financed through your dealership</Typography>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>VIN</TableCell>
              <TableCell>License Plate</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Collection Date</TableCell>
              <TableCell>Finance</TableCell>
              <TableCell>Telematics <IconButton onClick={(event) => refreshTelematics()} size="large"><LoopIcon /></IconButton></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assets?.map((row: Asset, index: number) => {
              const matchingTelematicsInfo = roadVehicles?.find(x => x.vin === row.usageSourceId)
              return (
                <TableRow key={row.id ?? index}>
                  <TableCell component="th" scope="row">
                    <TextField style={{ minWidth: "200px" }} variant="outlined" value={row.usageSourceId} onChange={(event: any) => onPropertyChange(index, event, (currentAssets: Asset[], index: number, event: any) => currentAssets[index].usageSourceId = event.target.value)} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <TextField style={{ minWidth: "150px" }} variant="outlined" value={row.alternativeId} onChange={(event: any) => onPropertyChange(index, event, (currentAssets: Asset[], index: number, event: any) => currentAssets[index].alternativeId = event.target.value)} />
                  </TableCell>
                  <TableCell>{row.make} {row.model} {row.trim} {row.yearOrVersion}</TableCell>
                  <TableCell>
                    <DesktopDatePicker
                      inputFormat="yyyy-MM-dd"
                      value={row.collectionDate}
                      onChange={(value: any) => onPropertyChange(index, value, (currentAssets: Asset[], index: number, event: any) => currentAssets[index].collectionDate = event)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography>{row.subscriptionName}</Typography>
                  </TableCell>
                  <TableCell>{matchingTelematicsInfo?.state?.mileage ? `Connected, read ${matchingTelematicsInfo?.state?.mileage.toLocaleString("en-gb", { minimumFractionDigits: 0, maximumFractionDigits: 0 })} miles at ${format(parseISO(matchingTelematicsInfo.dateTimeStateLastUpdated), "dd/MM/yyyy HH:mm:ss")}` : `Not Connected`}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container style={{ marginTop: "30px" }}>
        {
          biggerThanPhone
            ?
            (
              <>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle2">Bulk Upload Vehicles</Typography>
                  <FormControl component="fieldset" style={{ width: "100%" }}>
                    <FormGroup aria-label="position">
                      <FormControl margin="normal"
                        variant="outlined">
                        <InputLabel id="sub-id-label" className={classes.inputLabel}>Finance</InputLabel>
                        <Select
                          labelId="sub-id-label"
                          id="sub-id"
                          value={subscriptionIdForUpload}
                          onChange={handleSubscriptionIdChange}
                          label="Finance"
                        >
                          {
                            /** Get the unique values in the array **/
                            [...new Set(assets?.map(asset => asset.subscriptionId))].map(subscriptionId => {
                              const subscriptionName = assets?.find(x => x.subscriptionId === subscriptionId)?.subscriptionName
                              return (
                                <MenuItem value={subscriptionId}>{subscriptionName}</MenuItem>
                              )
                            })
                          }
                        </Select>
                      </FormControl>
                      <Grid container direction="row">
                        <Grid item xs={1}>
                          <InfoIcon />
                        </Grid>
                        <Grid item xs={11}>
                          <Typography>Your files have to be in a certain format for us to be able to read them. Please download an example <a download="Example.xlsx" href="/example-files/Example.xlsx">here</a>.</Typography>
                        </Grid>
                      </Grid>

                      <Button
                        variant="outlined"
                        component="label"
                      >
                        Upload Files
                        <input
                          onChange={handleFileSelected}
                          type="file"
                          accept="*"
                          multiple
                          hidden
                        />
                      </Button>
                      {
                        files.map(file => file.name).join(", ")
                      }
                      {
                        uploadFileButton()
                      }
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={5}>

                </Grid>
              </>
            )
            :
            (
              null
            )
        }
        <Grid item xs={12} md={2}>
          <Button style={{ width: '90%', marginTop: '10px' }} onClick={(event) => refresh()} color="secondary" variant='outlined' disabled={modificationState !== ModificationState.ModifiedButNotSaved}>Discard Your Changes</Button>
        </Grid>
        <Grid item xs={12} md={2}>
          {
            modificationState === ModificationState.SuccessfullyModifiedAndSaved
              ?
              (
                <Button style={{ width: '90%', marginTop: '10px' }} color="primary" variant='outlined' className={classes.success}>Saved!</Button>
              )
              :
              (
                <Button style={{ width: '90%', marginTop: '10px' }} onClick={(event) => updateAssets(assets ?? []).then((newAssets) => { setAssets([...newAssets]); setModificationState(ModificationState.SuccessfullyModifiedAndSaved) })} color="primary" variant='outlined' disabled={modificationState === ModificationState.Unmodified}>Save Your Changes</Button>
              )
          }
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

export { Vehicles }