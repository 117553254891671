/* eslint-disable react/no-array-index-key */
import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";

import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { MenuLink } from "../models/SiteMetaData";

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {},
    menuItemIcon: {
      color: theme.palette.text.secondary,
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
  })
);

const AppMenuItem: React.FC<MenuLink> = (props) => {
  const { title, url, nestedLinks = [] } = props;
  const classes = useStyles();
  const isExpandable = nestedLinks && nestedLinks.length > 0;
  const [open, setOpen] = React.useState(false);

  function handleClick(event: any) {
    setOpen(!open);
    event.stopPropagation();
  }

  const MenuItemRoot = (
    <ListItem button className={classes.menuItem} onClick={handleClick}>
      <ListItemText
        primary={
          isExpandable ? (
            <>{title}</>
          ) : (
            <Link className={classes.link} to={url ?? ""}>
              {title}
            </Link>
          )
        }
        inset
      />
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </ListItem>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {nestedLinks.map((item, index) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <AppMenuItem {...item} key={index} />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

export default AppMenuItem;