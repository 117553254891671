import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Container, ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import { theme } from "./styles/Theme";
import Sidebar from "./components/SideBar";
import HomePage from "./pages/HomePage";
import { useEffect, useState } from "react";
import { ClientPrincipal } from "./models/ClientPrincipal";
import { getUserInfo, isUserLoggedIn } from "./services/UserService";
import financialVideoDesktop from "./content/financial-desktop-cropped-hd.webm";
import financialVideoMobile from "./content/financial-mobile-cropped-hd.webm";
import financialPosterDesktop from "./content/financial-desktop-thumbnail-hd.webp";
import financialPosterMobile from "./content/financial-mobile-thumbnail-hd.webp";
import Hero from "./components/Hero";
import { menuLinks } from "./data/MenuLinks";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


function App() {
  const [user, setUser] = useState<ClientPrincipal | null>(null);
  useEffect(() => {
    getUserInfo().then((user) => setUser(user));
  }, []);
  let loggedIn = isUserLoggedIn(user);
  return loggedIn ? (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <Sidebar title="Dealership Portal" menuLinks={menuLinks} />
          <Container maxWidth={false}>
            <HomePage />
          </Container>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  ) : (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <Hero
            videoSourceDesktop={financialVideoDesktop}
            videoSourceMobile={financialVideoMobile}
            videoPosterDesktop={financialPosterDesktop}
            videoPosterMobile={financialPosterMobile}
            mainText="Dealership Portal"
            bodyText={
              <>
                Login to enable your sales financed through Zeti's ZERO platform
              </>
            }
            ctaLink="/login"
            ctaText="Sign in / up"
          />
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;