import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Vehicles } from '../components/Vehicles';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        homeDiv: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: "58px",
          },

        }
    }))
const HomePage = () => {
    const classes = useStyles();
    return (
        <div className={classes.homeDiv}>
            <Typography variant="h4" gutterBottom>Welcome to your Dealership Portal</Typography>
            <Vehicles />
        </div>
    )
}

export default HomePage;