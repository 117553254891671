import { ClientPrincipal } from "../models/ClientPrincipal";

async function getUserInfo(): Promise<ClientPrincipal> {
    const response = await fetch('/.auth/me');
    const payload = await response.json();
    const { clientPrincipal } = payload;
    return clientPrincipal;
}

async function isUserLoggedInAsync(clientPrincipal: ClientPrincipal | null) {
    if(clientPrincipal == null) clientPrincipal = await getUserInfo();
    return clientPrincipal.userRoles.includes("authenticated")
}

function isUserLoggedIn(clientPrincipal: ClientPrincipal | null) {
    return clientPrincipal?.userRoles?.includes("authenticated")
}
export { getUserInfo, isUserLoggedInAsync, isUserLoggedIn }