/* eslint-disable no-shadow */
import * as React from "react";
import { Link } from "react-router-dom";
import {
    List,
    AppBar,
    Typography,
    Theme,
    Toolbar,
    IconButton,
    Drawer,
    useMediaQuery,
    useTheme,
    Box,
    ClickAwayListener,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import MenuIcon from "@mui/icons-material/Menu";
import { MenuLink } from "../models/SiteMetaData";
import AppMenuItem from "./AppMenuItem";
import { SignInSignOutButton } from "../auth/SignInSignOutButton";

import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';

const drawerWidth = 280;
const sidebarIconSize = "2.5rem";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        title: {
            flexGrow: 1,
            color: theme.palette.text.secondary,
            fontSize: "1.1rem",
            margin: "10px 0px 0px 10px",
        },
        appBar: {
            backgroundColor: theme.palette.primary.main,
            "@media print": {
                display: "none",
            },
            [theme.breakpoints.up("sm")]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        menuLink: {
            cursor: "pointer",
            marginRight: theme.spacing(2),
            textDecoration: "none",
            color: theme.palette.text.secondary,
        },
        homeLink: {
            flexGrow: 1,
            textDecoration: "none",
            color: theme.palette.text.secondary,
        },
        drawerLink: {
            textDecoration: "none",
            color: theme.palette.primary.contrastText,
        },
        sidebarBottomBox: {
            position: "absolute",
            bottom: 0,
        },
        user: {
            textAlign: "right",
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        list: {
            width: "250px",
            backgroundColor: theme.palette.primary.main,
            padding: "10px",
            height: "100%",
        },
        active: {
            color: theme.palette.secondary.main,
        },
        drawer: {
            backgroundColor: theme.palette.primary.main,
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: theme.palette.primary.main,
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
        },
        sidebar: {
            "@media print": {
                display: "none",
            },
        },
        // Classes for sidebar component
        sidebarList: {
            listStyle: "none",
            listStyleType: "none",
            margin: 0,
            padding: 0,
        },
        primNav: {
            position: "fixed",
            height: "58px",
            width: "100vw",
            fontSize: "1rem",
            textTransform: "uppercase",
            backgroundColor: "#1844a0",
            display: "flex",
            flexDirection: "column",
            transition: "height 246ms 0.5s ease",
            paddingTop: "58px",
            overflowX: "hidden",
            overflowY: "hidden",
            boxSizing: "border-box",
            zIndex: 999,
            "@media (min-width: 650px)": {
                height: "101vh",
                width: "58px",
                transition: "width 246ms 0.5s ease",
            },
            "& > ul": {
                height: "101vh",
                overflowY: "auto",
                overflowX: "hidden",
            },
            "& li": {
                fontWeight: 400,
                position: "relative",
            },
            "& li a": {
                position: "relative",
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
                color: "white",
                textDecoration: "none",
            },
        },
        sidebarIcon: {
            height: "40px",
            flexShrink: 0,
            width: "40px",
            padding: "6px",
            marginRight: "5px",
            paddingTop: "20px",
            paddingBottom: "30px",
            color: "#e6e6e6",
        },
        secNav: {
            paddingLeft: "0px",
            marginLeft: "63px",
            borderLeft: "1px solid black",
            borderLeftColor: "#0065b8",
            overflow: "hidden",
            "& li": {
                listStyleType: "none",
                color: "#e6e6e6",
                maxHeight: "100px",
                transition: "max-height 0.1s",
                "@media (min-width: 650px)": {
                    maxHeight: "0px",
                    transition: "max-height 0.5s 0.5s",
                },
                "& a": {
                    textTransform: "initial",
                    display: "block",
                    color: "inherit",
                    padding: "0.75em 10px",
                },
            },
        },
        // Hamburger
        sidebarHamburger: {
            display: "none",
        },
        hamburgerLabel: {
            zIndex: 9999,
            position: "fixed",
            top: "14px",
            left: "17px",
            display: "block",
            height: "24px",
            width: "24px",
            "&:hover": {
                cursor: "pointer",
            },
        },
        hamburgerLabelText: {
            textTransform: "uppercase",
            textAlign: "center",
            position: "absolute",
            transform: "translateY(24px)",
            overflow: "hidden",
            transition: "width 0.25s 0.35s, color 0.45s 0.35s",
            color: "white",
            fontSize: "8px",
        },
        hamburgerLabelClosed: {
            right: 0,
            width: 0,
        },
        hamburgerLabelOpen: {
            width: "100%",
        },
        hamburgerButton: {
            position: "absolute",
            width: "100%",
            height: "2px",
            top: "50%",
            backgroundColor: "white",
            pointerEvents: "auto",
            transitionDuration: "0.35s",
            transitionDelay: "0.35s",
            "&:before, &:after": {
                position: "absolute",
                display: "block",
                width: "100%",
                height: "2px",
                left: "50%",
                backgroundColor: "white",
                content: `""`,
                transition: "transform 0.35s",
                transformOrigin: "50% 50%",
            },
            "&:before": {
                transform: "translate(-50%, -7px)",
            },
            "&:after": {
                transform: "translate(-50%, 7px)",
            },
        },
        // Classes to be applied to elements when checked
        primNavChecked: {
            height: "101vh",
            "@media (min-width: 650px)": {
                width: "275px",
            },
        },
        secNavChecked: {
            paddingLeft: "0px",
            "& > li": {
                maxHeight: "100px",
            },
            "& > li:nth-child(1)": {
                transition: "max-height 0.5s 0.67s ease-in",
            },
            "& > li:nth-child(2)": {
                transition: "max-height 0.5s 0.69s ease-in",
            },
            "& > li:nth-child(3)": {
                transition: "max-height 0.5s 0.71s ease-in",
            },
            "& > li:nth-child(4)": {
                transition: "max-height 0.5s 0.73s ease-in",
            },
            "& > li:nth-child(5)": {
                transition: "max-height 0.5s 0.75s ease-in",
            },
            "& > li:nth-child(6)": {
                transition: "max-height 0.5s 0.77s ease-in",
            },
            "& > li:nth-child(7)": {
                transition: "max-height 0.5s 0.79s ease-in",
            },
            "& > li:nth-child(8)": {
                transition: "max-height 0.5s 0.81s ease-in",
            },
            "& > li:nth-child(9)": {
                transition: "max-height 0.5s 0.83s ease-in",
            },
            "& > li:nth-child(10)": {
                transition: "max-height 0.5s 0.85s ease-in",
            },
        },
        hamburgerButtonChecked: {
            backgroundColor: "transparent",
            transform: "rotate(90deg)",
            "&:before": {
                transform: "translate(-50%, -50%) rotate(45deg)",
            },
            "&:after": {
                transform: "translate(-50%, -50%) rotate(-45deg)",
            },
        },
    })
);
interface HeaderProps {
    title: string;
    menuLinks: MenuLink[];
}

const Header: React.FC<HeaderProps> = ({ title, menuLinks }) => {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
    const [sidebarChecked, setSidebarChecked] = React.useState<boolean>(false);

    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }

            setDrawerOpen(open);
        };

    const theme = useTheme();
    const biggerThanMobile = useMediaQuery(theme.breakpoints.up("md"));

    const mobileMenuDrawer = (links: MenuLink[]) => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List style={{ height: "100%" }}>
                {links.map((menuLink) => (
                    <AppMenuItem
                        title={menuLink.title}
                        url={menuLink.url}
                        nestedLinks={menuLink.nestedLinks}
                    />
                ))}
                <Box width="250px" style={{ bottom: 30, position: "absolute" }}>
                    <SignInSignOutButton />
                </Box>
            </List>
        </div>
    );

    const mobileAppBar = ({ title, menuLinks }: HeaderProps) => (
        <AppBar className={classes.appBar} position="static">
            <Toolbar>
                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    aria-haspopup="true"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                    size="large">
                    <MenuIcon style={{ color: "white" }} />
                </IconButton>
                <Link className={classes.homeLink} to="/">
                    <Typography className={classes.title} variant="h6">
                        {title}
                    </Typography>
                </Link>
            </Toolbar>
            <Drawer
                classes={{ paper: classes.drawer }}
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                {mobileMenuDrawer(menuLinks)}
            </Drawer>
        </AppBar>
    );

    const desktopAppBar = ({ title, menuLinks }: HeaderProps) => (
        <ClickAwayListener onClickAway={() => setSidebarChecked(false)}>
            <div className={classes.sidebar}>
                <input
                    type="checkbox"
                    checked={sidebarChecked}
                    className={classes.sidebarHamburger}
                />
                <label
                    className={classes.hamburgerLabel}
                    onClick={() => setSidebarChecked(!sidebarChecked)}
                >
                    <i
                        className={
                            sidebarChecked
                                ? `${classes.hamburgerButton} ${classes.hamburgerButtonChecked}`
                                : classes.hamburgerButton
                        }
                    />
                    <span className={classes.hamburgerLabelText}>
                        <span
                            className={`${classes.hamburgerLabelClosed} ${classes.hamburgerLabelText}`}
                        >
                            close
                        </span>
                        <span
                            className={`${classes.hamburgerLabelOpen} ${classes.hamburgerLabelText}`}
                        >
                            open
                        </span>
                    </span>
                </label>
                <nav
                    className={
                        sidebarChecked
                            ? `${classes.primNav} ${classes.primNavChecked}`
                            : classes.primNav
                    }
                >
                    <ul className={classes.sidebarList}>
                        <li>
                            <Link to="/">
                                <HomeIcon
                                    className={classes.sidebarIcon}
                                    style={{ fontSize: sidebarIconSize }}
                                />
                                Home
                            </Link>
                            <ul
                                className={
                                    sidebarChecked
                                        ? `${classes.secNav} ${classes.secNavChecked}`
                                        : classes.secNav
                                }
                            >
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/logout">
                                <PersonIcon
                                    className={classes.sidebarIcon}
                                    style={{ fontSize: sidebarIconSize }}
                                />
                                Sign Out
                            </Link>
                            <ul
                                className={
                                    sidebarChecked
                                        ? `${classes.secNav} ${classes.secNavChecked}`
                                        : classes.secNav
                                }
                            >
                                <li>
                                    <a href="/logout">Sign Out</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </ClickAwayListener>
    );

    return biggerThanMobile
        ? desktopAppBar({ title, menuLinks })
        : mobileAppBar({ title, menuLinks });
};

export default Header;