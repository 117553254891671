import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { red, grey } from "@mui/material/colors";

const primary = "#1844A0";
const secondary = "#C41237";
const white = "#ffffff";
// eslint-disable-next-line @typescript-eslint/no-use-before-define
const error = red[500];
const contrast = "#212121";

const primaryText = grey[900];
// A custom theme for this app
let theme = createTheme({
  components: {
    MuiInputBase: {
      defaultProps: {
        // Needed to prevent adding a global style for every input field
        disableInjectingGlobalStyles: true,
      },
    },
  },
  typography: {
    h1: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    h2: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    h3: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    h4: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    h5: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    h6: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    subtitle1: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    subtitle2: {
      fontFamily: "Montserrat",
      color: primaryText,
    },
    fontFamily: "Poppins",
  },
  palette: {
    mode: "light",
    primary: {
      main: primary,
      contrastText: contrast,
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: error,
    },
    background: {},
    text: {
      primary: primaryText,
      secondary: white,
    },
  },
});

theme = responsiveFontSizes(theme);

export { theme };