import { MenuLink } from "../models/SiteMetaData";

const menuLinks: MenuLink[] = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Sign Out",
    url: "/logout",
  },
];

export { menuLinks };